import React, { PureComponent } from 'react';

class SelectList extends PureComponent {
    
    render() {
        return (
            <select onChange={(event) => this.props.onChange(event)}>
                {this.props.items.map(item =>
                    <option key={item}>
                      {item}
                    </option>
                )}
            </select>
        );
    }
}

export default SelectList;