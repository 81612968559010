export const fetchWeatherByCount = async count => {
    let return_result = null;
    try {
        const response = await fetch(
            'http://dataservice.accuweather.com/currentconditions/v1/topcities/' + encodeURIComponent(count) +
            '?apikey=KWfmmApUqddMOsZO8chfG14qnHIGdVgV&details=true',
        );
        const response_result = await response.json();
        return_result = response_result;
    } catch (err) {
        console.log("\n ERROR 1 fetch err: " + err);
    }

    console.log("\n --- return_result: " + JSON.stringify(return_result));

    return {
        weather_result: return_result
    };

};


