import React, { PureComponent } from 'react';
//import ButtonList from './ButtonList';
import SelectList from './SelectList';

import './Form.css';

class Form extends PureComponent {
    constructor(props) {
        super(props);

        let obj_arr = ["50", "150"];
         
        this.selected_number_list = "50";

        this.state = {
            value: 1,
            select_list: obj_arr,
            city_count: "50"
        };

       // this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        //this.setState({
        //    count: event.target.value
        //});

        this.selected_number_list = event.target.value;

        this.setState({
            city_count: event.target.value
        });
        
        console.log("\n --list item changed selected value is : " + event.target.value +
            " \n\n selected_number_list value now: " + this.selected_number_list);
    }

    render() {
        return (
            <div>
                <SelectList items={this.state.select_list} onChange={this.handleChange.bind(this)} />
                <button className="Form" onClick={() => this.props.onSubmit(this.selected_number_list)}> top {this.state.city_count} cities worldwide weather</button>

            </div>
        );
    }

}

export default Form;