//import React from 'react';
//import logo from './logo.svg';
//import './App.css';

//function App() {
//  return (
//    <div className="App">
//      <header className="App-header">
//        <img src={logo} className="App-logo" alt="logo" />
//        <p>
//          Edit <code>src/App.js</code> and save to reload.
//        </p>
//        <a
//          className="App-link"
//          href="https://reactjs.org"
//          target="_blank"
//          rel="noopener noreferrer"
//        >
//          Learn React
//        </a>
//      </header>
//    </div>
//  );
//}

//export default App;

//import React, { Component } from 'react';
//import Geolocation from './components/Geolocation';
//import './App.css';

//class App extends Component {
//    render() {
//        return (
//            <div className="App">
//                <Geolocation />
//            </div>
//        );
//    }
//}

//export default App;

import React, { Component } from 'react';
//import Weatherlocation from './components/Weatherlocation';
//import GoogleMap from './components/GoogleMap';
//import ButtonList from './components/ButtonList';
import WeatherInfo from './components/WeatherInfo';

import './App.css';

class App extends Component {
    render() {
        return (
            <div className="App">
                <WeatherInfo />

            </div>
        );
    }
}

export default App;

