import React from 'react';
import './css/WeatherList.css';

//export default function WeatherList({ cityName, countryName}) {
const WeatherList = ({weather_obj }) => (

    
        //<div>
        //    <div>city: {city}</div>
        //    <div>country: {country}</div>
        //</div>

    <div>
     
          
        <table className="weatherTable">
            <tbody>
            <tr>
                <th>city</th>
                <th>country</th>
                <th>temperature  &#8451;</th>
                <th>weather situation</th>
                <th>image</th>
            </tr>
            {weather_obj.map(item => (
                
                <tr key={item.Key}>
                        <td>{item.EnglishName}</td>
                        <td>{item.Country.EnglishName}</td>
                        <td>{item.Temperature.Metric.Value}</td>
                        <td>{item.WeatherText}</td>
                        <td><img src={item.WeatherIcon} alt='weather' /></td>
                    </tr>
                    ))}
                    </tbody>
                </table>
          
    </div>
);

export default WeatherList;